import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollSmoother from "gsap/ScrollSmoother";
import Splide from '@splidejs/splide';
// import Splidevideo from '@splidejs/splide-extension-video';
import LazyLoad from "vanilla-lazyload";

// --------------------------------------------------
// 🐌 General
// --------------------------------------------------

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

gsap.defaults({
  ease: 'power1.inOut',
  duration: .5
})

// --------------------------------------------------
// 💤 Lazy Loading
// --------------------------------------------------

const lazyLoadInstance = new LazyLoad({
  // Your custom settings go here
  elements_selector: "[data-lazy]",
  callback_loaded: (el) => {
    gsap.to(el, {
      autoAlpha: 1,
      duration: .5,
      ease: 'power1.inOut',
      onComplete: () => {
        el.dispatchEvent(new Event('lazyloaded'));
      }
    })
  }
});

// --------------------------------------------------
// ScrollSmoother
// --------------------------------------------------

// create the scrollSmoother before your scrollTriggers
let smoother = ScrollSmoother.create({
  smooth: 1, // how long (in seconds) it takes to "catch up" to the native scroll position
  effects: true, // looks for data-speed and data-lag attributes on elements
  // smoothTouch: 0.1, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
});

// ignore Mobile Resize
ScrollTrigger.config({ ignoreMobileResize: true });

// --------------------------------------------------
// Scroll to
// --------------------------------------------------

gsap.utils.toArray(".scroll-to").forEach(function (link, i) {
  link.addEventListener("click", (e) => {
    var id = e.target.getAttribute("href");
    console.log(id);
    smoother.scrollTo(id, true, "top 10%");
    e.preventDefault();
  });
});

// to view navigate to -  https://cdpn.io/pen/debug/XWVvMGr#section3
window.onload = (event) => {
  console.log("page is fully loaded");

  let urlHash = window.location.href.split("#")[1];

  let scrollElem = document.querySelector("#" + urlHash);

  console.log(scrollElem, urlHash);

  if (urlHash && scrollElem) {
    gsap.to(smoother, {
      scrollTop: smoother.offset(scrollElem, "top 10%"),
      duration: 0
    });
  }
};

// --------------------------------------------------
// Hide Menu on Scroll
// --------------------------------------------------

/* When the user scrolls down, hide the navbar. When the user scrolls up, show the navbar */
var prevScrollpos = window.scrollY;
window.onscroll = function() {
  var currentScrollPos = window.scrollY;
  
  if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
    document.getElementById("header").classList.add('scrolled-header');
  } else {
    document.getElementById("header").classList.remove('scrolled-header');
  }

  // if (prevScrollpos > currentScrollPos) {
  //   document.getElementById("header").style.top = "0";
  // } else {
  //   document.getElementById("header").style.top = "-70px";
  // }

  prevScrollpos = currentScrollPos;
}

// --------------------------------------------------
// 🎠 Carousels
// --------------------------------------------------

let bannerCarousel = document.querySelector('.splide--banner');

if (bannerCarousel) {
  let bannerSplide = new Splide(bannerCarousel, {
    type: 'fade',
    rewind: true,
    speed: 1200,
    pauseOnHover: false,
    interval: 3400,
    autoplay: true,
    arrows: false,
    pagination: false,
    perPage: 1,
    perMove: 1,
    focus: 'center',
    width: '100%',
    updateOnMove: true
  });
  
  // Trigger lazyloading after splide has cloned the slides
  // bannerSplide.on('ready', () => {
  //   setupLazyLoading(bannerCarousel);
  // })
  
  // let firstImage = document.querySelector('.home-gallery__image');

  // setupLazyLoading(data.next.container);
  
  // firstImage.addEventListener('lazyloaded', () => {
  //   setupCarousel(data.next.container);
  // })

  bannerSplide.mount();
}

let largeCarousel = document.querySelector('.splide--large-carousel');

if (largeCarousel) {
  let largeSplide = new Splide(largeCarousel, {
    type: 'fade',
    rewind: true,
    speed: 1200,
    pauseOnHover: false,
    arrows: false,
    pagination: false,
    perPage: 1,
    perMove: 1,
    focus: 'center',
    width: '100%',
    updateOnMove: true
  });
  
  // Add event listeners for custom controls
  document.querySelector('.prev').addEventListener('click', function () {
    largeSplide.go('<');
  });

  document.querySelector('.next').addEventListener('click', function () {
    largeSplide.go('>');
  });

  // Trigger lazyloading after splide has cloned the slides
  // largeSplide.on('ready', () => {
  //   setupLazyLoading(largeCarousel);
  // })
  
  largeSplide.mount();
}

// Initialize all Splide instances
var splideContainers = document.querySelectorAll('.extended-industry__banner');

if (splideContainers) {

  splideContainers.forEach(function(container) {
    var splide = new Splide(container.querySelector('.splide'), {
      // Your options for each carousel
      type: 'fade',
      rewind: true,
      speed: 1200,
      pauseOnHover: false,
      autoplay: false,
      arrows: false,
      pagination: false,
      autoHeight: true,
      focus: 'center',
      width: '100%',
      updateOnMove: true
    }).mount();

    // Add event listeners for custom controls
    container.querySelector('.prev').addEventListener('click', function () {
      splide.go('<');
    });

    container.querySelector('.next').addEventListener('click', function () {
      splide.go('>');
    });

  });

};


// Initialize all Splide instances
var labContainers = document.querySelectorAll('.lab__image--carousel');

if (labContainers) {

  labContainers.forEach(function(labContainer) {
    var splide = new Splide(labContainer.querySelector('.splide'), {
      // Your options for each carousel
      type: 'fade',
      rewind: true,
      speed: 1200,
      pauseOnHover: false,
      autoplay: true,
      arrows: false,
      pagination: false,
      autoHeight: true,
      focus: 'center',
      width: '100%',
      updateOnMove: true
    }).mount();

  });

};

// --------------------------------------------------
// Fade in and up
// --------------------------------------------------

const fadeInUp = gsap.utils.toArray('.fade-in-up');

// Set things up
gsap.set(fadeInUp, {
  autoAlpha: 0, 
  y: 45
});

fadeInUp.forEach((item, i) => {
  // Set up your animation
  const anim = gsap.to(item, {
    duration: 1.4,
    autoAlpha: 1,
    y: 0,
    ease: 'power3',
    paused: true
  });
  
  // Use callbacks to control the state of the animation
  ScrollTrigger.create({
    trigger: item,
    start: "top 92%",
    stagger: .2,
    once: true,
    onEnter: self => {
      // If it's scrolled past, set the state
      // If it's scrolled to, play it
      self.progress === 1 ? anim.progress(1) : anim.play()
    }
  });
});

// --------------------------------------------------
// Line animations
// --------------------------------------------------

var lines = gsap.utils.toArray('.line');

// Set things up
gsap.set(lines, {
  autoAlpha: 1
});

lines.forEach((line) => {

  gsap.from(line, { 
    autoAlpha: 0,
    duration: 2,
    scaleX: 0, 
    transformOrigin:"0% 100%",
    ease: "power4",
    scrollTrigger: {
      trigger: line
    }
  });

});

// --------------------------------------------------
// Intranet videos
// --------------------------------------------------

function setupVideoPlayer(containerId) {
  // Get all buttons in the specified container
  var buttons = document.querySelectorAll('#' + containerId + ' .intranet-videos__button');

  // Attach click event listeners to each button
  buttons.forEach(function(button) {
    button.addEventListener('click', function() {
      // Get the associated video index from the data attribute
      var videoIndex = button.getAttribute('data-video-index');
      
      // Play the corresponding video
      playVideo(containerId, videoIndex);
    });
  });
}

function playVideo(containerId, videoNumber) {
  // Pause all videos in the specified container before playing the selected one
  pauseAllVideos(containerId);

  // Get the iframe element
  var video = document.querySelector('#' + containerId + ' .intranet-videos__videos [data-video-wrapper-index="' + videoNumber + '"]');
  console.log(videoNumber);
  // Play the video
  // iframe.src = iframe.src + '&autoplay=1';
  video.style.display = 'block';
}

function pauseAllVideos(containerId) {
  var iframes = document.querySelectorAll('#' + containerId + ' .intranet-videos__videos iframe');
  for (var i = 0; i < iframes.length; i++) {
    // Pause each video by setting the src to the same value
    iframes[i].src = iframes[i].src;
  }

  var videos = document.querySelectorAll('#' + containerId + ' .intranet-videos__videos .intranet-videos__video-wrapper');
  for (var i = 0; i < videos.length; i++) {
    // Pause each video by setting the src to the same value
    videos[i].style.display = 'none';
  }
}

// Setup for the first set of videos and buttons
setupVideoPlayer('videos-1');

// Setup for the second set of videos and buttons
setupVideoPlayer('videos-2');

// --------------------------------------------------
// Map Country/Clients list
// --------------------------------------------------

gsap.set('.interactive-map__client', { autoAlpha: 0 }); // Set initial opacity to 1 for visible elements
svgRoot = document.getElementById("world-map");


// Add event listeners to all buttons
var mapButtons = document.querySelectorAll('#map-country-buttons button');
mapButtons.forEach(function(mapButton, index) {
  mapButton.addEventListener('click', function() {
    var mapCountry = this.getAttribute('data-mapcountry');
    filterMapClients(mapCountry);
  });
  // Trigger click event on the first button
  if (index === 0) {
    mapButton.click();
  }
});

function filterMapClients(mapCountry) {
  // Remove underline from all buttons
  mapButtons.forEach(function(mapButton) {
    mapButton.querySelector('.interactive-map__text-country-button-title').classList.remove('underline');
  });

    // Change fill color of SVG paths for the selected country
    var paths = document.querySelectorAll('path[data-name]');
    paths.forEach(function(path) {
      var pathCountry = path.getAttribute('data-name');
      if (pathCountry === mapCountry) {
        // Change the fill color to your desired color
        path.classList.add('active-map-country'); // Change to the desired color code
        var square = path.getAttribute('data-square');
        console.log(square);
        if (square == '1') {
          gsap.to(svgRoot, 1.5, {attr:{ viewBox:"0 150 1000 500"}})
          console.log('group 1');
        } else if (square == '2') {
          gsap.to(svgRoot, 1.5, {attr:{ viewBox:"350 350 400 400"}})
          console.log('group 2');
        } else if (square == '3') {
          gsap.to(svgRoot, 1.5, {attr:{ viewBox:"450 550 300 500"}})
          console.log('group 3');
        } else {
          gsap.to(svgRoot, 1.5, {attr:{ viewBox:"500 150 700 400"}})
          console.log('group 4');
        };

      } else {
        // Reset the fill color for other paths
        path.classList.remove('active-map-country'); // Change to the default color code
      }
    });

    // Underline the selected button
    var selectedMapButton = document.querySelector('#map-country-buttons button[data-mapcountry="' + mapCountry + '"]');
    if (selectedMapButton) {
      selectedMapButton.querySelector('.interactive-map__text-country-button-title').classList.add('underline');
    }

}

// --------------------------------------------------
// 🪗 Accordion
// --------------------------------------------------

const accordions = document.querySelectorAll('.accordion');

const toggleAccordion = (accordionButton) => {
  // Close other accordion items
  // accordions.forEach(otherAccordion => {
  //   const otherAccordionButton = otherAccordion.querySelector('button');
  //   if(otherAccordionButton !== accordionButton) {
  //     otherAccordionButton.setAttribute('aria-expanded', 'false');
  //   }
  // });
  
  // Expand the accordion
  if(accordionButton.getAttribute('aria-expanded') !== 'true') {
    accordionButton.setAttribute('aria-expanded', 'true');
  }
  // Close the accordion
  else {
    accordionButton.setAttribute('aria-expanded', 'false');
  }

}

accordions.forEach(accordion => {
  const accordionButton = accordion.querySelector('button');
  accordionButton.addEventListener('click', () => {
    toggleAccordion(accordionButton);
  })
})

// --------------------------------------------------
// 🎥 Serve correct video size relative to screen size
// --------------------------------------------------

// function updateVideoSources() {
//   heroVideos.forEach(multiSourceVideoElement => {
//       const sources = multiSourceVideoElement.getElementsByTagName("source");
//       const screenWidth = window.innerWidth;

//       let selectedSource = getSourceByResolution(sources, "original"); // Default to original if no other source matches

//       if (screenWidth > 2000) {
//           selectedSource = getSourceByResolution(sources, "1080");
//       } else if (screenWidth > 540) {
//           selectedSource = getSourceByResolution(sources, "720");
//       } else if (screenWidth > 400) {
//           selectedSource = getSourceByResolution(sources, "540");
//       } else {
//           selectedSource = getSourceByResolution(sources, "360");
//       }

//       multiSourceVideoElement.setAttribute("data-src", selectedSource.getAttribute("data-src"));
//       multiSourceVideoElement.load();
//   });
// }

// function getSourceByResolution(sources, resolution) {
//   for (const source of sources) {
//     if (source.getAttribute("data-resolution") === resolution) {
//         return source;
//     }
//   }

//   // If the exact resolution isn't found, find the next numerically higher resolution source
//   let nextResolution = "original";
//   for (const source of sources) {
//     const sourceResolution = source.getAttribute("data-resolution");
//     if (!isNaN(sourceResolution) && sourceResolution > resolution && (nextResolution === "original" || sourceResolution < nextResolution)) {
//         nextResolution = sourceResolution;
//     }
//   }

//   for (const source of sources) {
//     if (source.getAttribute("data-resolution") === nextResolution) {
//         return source;
//     }
//   }

//   return null;
// }

// let heroVideos = document.querySelectorAll('.hero__image video');
// if (heroVideos) {
//   // Initial update based on viewport width
//   updateVideoSources();
// }

// --------------------------------------------------
// Slide in menu on mobile
// --------------------------------------------------

let menuButton = document.getElementById('menu-button');
let menuModal = document.getElementById('menu-modal');
let closeButton = document.getElementById('menu-modal-close');

menuButton.addEventListener('click', () => {
  console.log('clicked')
  menuModal.classList.add('active');
  document.body.style.overflow = 'hidden';
});

closeButton.addEventListener('click', () => {
  menuModal.classList.remove('active');
  document.body.style.overflow = 'auto';
});


// --------------------------------------------------
// 💌 Uniform test
// --------------------------------------------------

  var form = document.querySelector('.contact-form');
  var message = document.getElementById('result-message');
  var fields = {};
  form.querySelectorAll('[name]').forEach(function (field) {
      fields[field.name] = field;
  });

  // Displays all error messages and adds 'error' classes to the form fields with
  // failed validation.
  var handleError = function (response) {
      var errors = [];
      for (var key in response) {
          if (!response.hasOwnProperty(key)) continue;
          if (fields.hasOwnProperty(key)) fields[key].classList.add('error');
          Array.prototype.push.apply(errors, response[key]);
      }
      message.innerHTML = errors.join('<br>');
  }

  var onload = function (e) {
      if (e.target.status === 200) {
          message.innerHTML = 'Success!'
      } else {
          handleError(JSON.parse(e.target.response));
      }
  };

  var submit = function (e) {
      e.preventDefault();
      var request = new XMLHttpRequest();
      request.open('POST', e.target.action);
      request.onload = onload;
      request.send(new FormData(e.target));
      // Remove all 'error' classes of a possible previously failed validation.
      for (var key in fields) {
          if (!fields.hasOwnProperty(key)) continue;
          fields[key].classList.remove('error');
      }
  };
  form.addEventListener('submit', submit);



  
  